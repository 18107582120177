var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row position-relative",class:_vm.RoutePage == 'QuizFinal'
        ? 'm-0 p-0 w-100 big-div-quiz'
        : ' w-85 big-div',staticStyle:{"z-index":"100"},style:(_vm.getPageDesign())},[(_vm.pageDesign.pageStyles.jsCode)?_c('script2',[_vm._v(" "+_vm._s(_vm.pageDesign.pageStyles.jsCode)+" ")]):_vm._e(),(_vm.pageDesign.pageStyles.imageAlign == 'no')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),staticClass:"bg lazyload",style:(_vm.getbackgroundImage(
          _vm.IsImageAdded,
          _vm.pageDesign.imageBlock,
          _vm.pageDesign.pageStyles.imageAlign,
          _vm.isDesktopView
        ) + _vm.getBgDivStyles()),attrs:{"data-bg":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView))}}):_vm._e(),(_vm.showAlert)?_c('div',{staticClass:"AlertContainer"},[_c('div',{staticClass:"alert text-center fade show text-white alert-text",staticStyle:{"background":"#f27367","width":"fit-content"},attrs:{"role":"alert"}},[_c('span',[_c('i',{staticClass:"fa fa-exclamation-triangle mr-2",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.showAlertText))])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"px-0",class:[
        {
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division col-6': _vm.isDesktopView,
        },
      ],style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)])]),_c('div',{class:[!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout],style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
        _vm.getScrollCSS(
          _vm.pageDesign.pageStyles,
          _vm.isDesktopView,
          _vm.pageDesign.imageBlock.style,
          _vm.pageDesign.pageType
        ) +
        _vm.calculateContentWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive,
          _vm.pageDesign.pageStyles
        ))},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"flex-end","height":"100%"}},[_c('div',{staticClass:"h-100",staticStyle:{"overflow":"hidden scroll"}},[_c('div',[_c('PreviewProgressBar',{attrs:{"order":_vm.CurrentPage.order}})],1),_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind,class:['item-' + ind, pro.type === 'tooltip' ? 'tooltip-zIndex' : ''],attrs:{"data-aos":_vm.animationType,"data-aos-duration":_vm.getAnimationSpeedinMillis,"data-aos-delay":_vm.AOSAnimationDelay(ind),"data-aos-anchor":`.item-${ind == 0 ? ind : ind - 1}`}},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                  _vm.getPaddingCss(pro.style) +
                  _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                  _vm.getColorWithOpacity(pro.style) +
                  _vm.getTextRotate(pro.style) +
                  _vm.getLineHeight(pro.style) +
                  _vm.getTextAlign(pro.style) +
                  _vm.getFontStyle(pro.style,_vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getTextValue(pro.content))}}):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                  _vm.getColorWithOpacity(pro.style) +
                  _vm.getTextRotate(pro.style) +
                  _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'option')?_c('div',[(_vm.checkToalettsitsarClient)?_c('div',[_c('Toalettsitsar',{attrs:{"pro":pro},on:{"selectedOptions":(selected) => {
                        _vm.selectedOptions = selected;
                      }}})],1):_vm._e(),(_vm.checkFrieghtQuotClient)?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView)+ _vm.getPaddingCss(pro.style))},[_c('FreightQuot',{attrs:{"pro":pro,"currentQuizId":_vm.CurrentPage.id,"FrieghtQuotClient":_vm.FrieghtQuotClient},on:{"selectedOptions":_vm.handleFreightQuotSelectOptions}})],1):_vm._e(),(!_vm.checkToalettsitsarClient && !_vm.checkFrieghtQuotClient)?_c('div',[_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                      _vm.getTextAlign(pro.style))},[(_vm.isIkeaClientTagQuestion)?_c('div',{},[_c('p',{staticClass:"m-0 p-0",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.IkeaClientCounterValue)+" ")])]):_vm._e(),(_vm.getCurrentSelectedOptionType === 'text')?_c('div',[(pro.style.textAlign != 'end')?_c('div',{class:pro.textOptionAlignment == 'vertical'
                            ? 'd-flex flex-column'
                            : 'd-flex  flex-wrap'},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"form-check checkboxDiv",class:pro.textOptionAlignment == 'horizontal'
                              ? 'mr-2'
                              : 'mb-1',style:(_vm.selectedCheckboxColor(pro.style))},[_c('div',{staticClass:"d-flex align-items-center w-100",style:(`justify-content:${pro.style.textAlign};`)},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOptions),expression:"selectedOptions"}],staticClass:"form-check-input m-0 mr-2",style:({
                                height: pro.style.fontSize + 'px',
                                width: pro.style.fontSize + 'px',
                                position: 'relative',
                              }),attrs:{"type":"checkbox","id":index,"disabled":_vm.limitSelectionCheckbox(checkbox)},domProps:{"value":checkbox,"checked":Array.isArray(_vm.selectedOptions)?_vm._i(_vm.selectedOptions,checkbox)>-1:(_vm.selectedOptions)},on:{"change":function($event){var $$a=_vm.selectedOptions,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=checkbox,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedOptions=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedOptions=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedOptions=$$c}}}}),_c('label',{staticClass:"form-check-label m-0",style:(_vm.getColorCss(pro.style) +
                                _vm.getColorWithOpacity(pro.style) +
                                _vm.getFontStyle(pro.style)),attrs:{"for":index}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})])])])}),0):_c('div',{class:pro.textOptionAlignment == 'vertical'
                            ? 'd-flex flex-column'
                            : 'd-flex  flex-wrap'},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"form-check checkboxDiv",class:pro.textOptionAlignment == 'horizontal'
                              ? 'mr-2'
                              : 'mb-1',style:(_vm.selectedCheckboxColor(pro.style))},[_c('div',{staticClass:"d-flex align-items-center w-100",style:(`justify-content:${pro.style.textAlign};`)},[_c('label',{staticClass:"form-check-label m-0 mr-2",style:(_vm.getColorCss(pro.style) +
                                _vm.getColorWithOpacity(pro.style) +
                                _vm.getFontStyle(pro.style)),attrs:{"for":index}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOptions),expression:"selectedOptions"}],staticClass:"form-check-input m-0",style:({
                                height: pro.style.fontSize + 'px',
                                width: pro.style.fontSize + 'px',
                                position: 'relative',
                              }),attrs:{"type":"checkbox","id":index,"disabled":_vm.limitSelectionCheckbox(checkbox)},domProps:{"value":checkbox,"checked":Array.isArray(_vm.selectedOptions)?_vm._i(_vm.selectedOptions,checkbox)>-1:(_vm.selectedOptions)},on:{"change":function($event){var $$a=_vm.selectedOptions,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=checkbox,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectedOptions=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectedOptions=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectedOptions=$$c}}}})])])}),0)]):_vm._e(),(_vm.getCurrentSelectedOptionType === 'image')?_c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"d-flex flex-column",class:_vm.isDesktopView
                              ? `col-${12 / pro.OptionsPerRow}`
                              : `col-${12 / pro.OptionsPerMobile}`,style:(_vm.getDivAlignmentStyle(pro.style.textAlign) +
                            _vm.getSpaceBetweenRow(pro.style))},[_c('div',{staticClass:"ImageInputBlock position-relative",style:(_vm.getImageHeightWidth(
                                    pro.style,
                                    _vm.isDesktopView
                                  ) +
                                  `${
                                    _vm.IsSelectedImageExist(checkbox)
                                      ? `border:2px solid ${
                                          pro.style.selectedImageIconColor ||
                                          '#000000'
                                        };padding:2px;`
                                      : null
                                  }`),on:{"click":function($event){return _vm.checkSelectedImage(checkbox)}}},[_c('LazyImage',{key:_vm.ImageSrc(checkbox.image) ||
                                    '/images/image_placeholder.png' + index,staticStyle:{"cursor":"pointer","height":"100%","width":"100%"},style:(_vm.getBorderRadius(pro.style)),attrs:{"src":_vm.ImageSrc(checkbox.image) ||
                                    `/images/image_placeholder.png`,"alt":""}})],1),_c('div',[_c('p',{staticClass:"m-0 p-0 mt-2",style:(_vm.getColorCss(pro.style) +
                                _vm.getColorWithOpacity(pro.style) +
                                _vm.getFontStyle(pro.style)),domProps:{"innerHTML":_vm._s(checkbox.value)}})]),(
                              _vm.isIkeaClientCounterQuestion &&
                              _vm.checkSelectedOptionForCounter(checkbox)
                            )?_c('div',{staticClass:"counter-parent mx-2 pt-2"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('button',{staticStyle:{"width":"40px !important","height":"40px !important","border-radius":"50% !important","max-width":"40px !important","max-height":"40px !important","min-width":"40px !important","min-height":"40px !important","border":"none"},style:([{ backgroundColor: '#CDE4E3' }]),on:{"click":function($event){return _vm.incrementCounter(checkbox.id, 'decrement')}}},[_vm._v(" - ")]),_c('span',{staticStyle:{"width":"30px","border":"none"}},[_vm._v(_vm._s(_vm.currentCounterValue(checkbox.id)))]),_c('button',{staticStyle:{"width":"40px !important","height":"40px !important","border-radius":"50% !important","max-width":"40px !important","max-height":"40px !important","min-width":"40px !important","min-height":"40px !important","border":"none"},style:([{ backgroundColor: '#CDE4E3' }]),on:{"click":function($event){return _vm.incrementCounter(checkbox.id, 'increment')}}},[_vm._v(" + ")])])]):_vm._e()])}),0)]):_vm._e(),(_vm.getCurrentSelectedOptionType === 'tab')?_c('div',[(pro.textOptionAlignment == 'vertical')?_c('div',[_c('ul',{staticClass:"m-0 p-0",style:(`max-width: ${_vm.isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;
                                list-style: none;
                                display: inline-block;`)},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('li',{key:index,staticClass:"mb-2",class:_vm.isDesktopView ? 'Tab' : '',style:(`min-width:${ _vm.isDesktopView ?  pro.style.defaultTab.minTabWidthDesktop || 200 : pro.style.defaultTab.minTabWidthMobile || 200 }px; max-width: ${_vm.isDesktopView ?  pro.style.defaultTab.maxTabWidthDesktop || 500 : pro.style.defaultTab.maxTabWidthMobile || 500 }px;`)},[_c('div',[_c('button',{staticClass:"btn tab-btn border w-100",style:(_vm.isSelectedOptionExist(checkbox)
                                    ? _vm.getSelectedTabStyles(
                                        pro.style.selectedTab,
                                        pro.style
                                      )
                                    : _vm.getDefaultTabStyles(
                                        pro.style.defaultTab,
                                        pro.style
                                      )),on:{"click":function($event){return _vm.updateSelectedAnswer(checkbox)}}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})])])])}),0)]):_c('div',{},[_c('div',{staticClass:"m-0 p-0 row",staticStyle:{"display":"flex","flex-wrap":"wrap"},style:(`height:100%;max-width: 100%; justify-content:${pro.style.textAlign};`)},_vm._l((_vm.getOptionsArr),function(checkbox,index){return _c('div',{key:index,staticClass:"mb-2",class:[
                              _vm.isDesktopView ? 'Tab' : '',
                              _vm.isDesktopView
                                ? pro.optionSetting.horizontalOptionsPerRow
                                  ? `col-${
                                      12 /
                                      pro.optionSetting.horizontalOptionsPerRow
                                    }`
                                  : 'col-6 col-sm-6 col-md-4'
                                : pro.optionSetting
                                    .horizontalOptionsPerRowMobile
                                ? `col-${
                                    12 /
                                    pro.optionSetting
                                      .horizontalOptionsPerRowMobile
                                  }`
                                : 'col-6 col-sm-6 col-md-4',
                            ],staticStyle:{"flex-grow":"1"}},[_c('div',{staticStyle:{"height":"100% !important"}},[_c('button',{staticClass:"btn tab-btn border w-100",staticStyle:{"height":"100% !important"},style:(_vm.isSelectedOptionExist(checkbox)
                                    ? _vm.getSelectedTabStyles(
                                        pro.style.selectedTab,
                                        pro.style
                                      )
                                    : _vm.getDefaultTabStyles(
                                        pro.style.defaultTab,
                                        pro.style
                                      )),on:{"click":function($event){return _vm.updateSelectedAnswer(checkbox)}}},[_c('span',{domProps:{"innerHTML":_vm._s(checkbox.value)}})])])])}),0)])]):_vm._e(),(pro.optionSetting.optionType === 'dropdown')?_c('div',[_c('div',{staticClass:"select-div"},[_c('multiselect',{staticClass:"multiSelect",style:(_vm.getColorCss(pro.style) +
                            _vm.getColorWithOpacity(pro.style) +
                            _vm.getFontStyle(pro.style) +
                            _vm.QuestionPageStyle(pro.style)),attrs:{"options":_vm.getOptionsArr,"multiple":true,"min":_vm.getMultiMin,"max":_vm.getMultiMax,"close-on-select":false,"searchable":false,"label":"value","track-by":"value"},model:{value:(_vm.selectedOptions),callback:function ($$v) {_vm.selectedOptions=$$v},expression:"selectedOptions"}},[_c('template',{slot:"maxElements"},[_c('span')])],2)],1)]):_vm._e()])]):_vm._e()]):_vm._e(),(pro.type == 'imageComp')?_c('div',[_c('InlineImageComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'tooltip')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} `)},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView) +
                    _vm.getCircularBtn(pro.isBtnRounded)),on:{"mouseenter":function($event){pro.isTooltipVisible = true},"mouseleave":function($event){pro.isTooltipVisible = false}}},[(pro.useIcon)?_c('span',[_c('BIcon',{attrs:{"icon":pro.selectedIcon}})],1):_c('span',{style:({
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    })},[_vm._v(" "+_vm._s(pro.text)+" ")])]),(pro.isTooltipVisible)?_c('ToolTipComp',{attrs:{"tooltipData":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e()],1):_vm._e()])])})],2),_c('div',[_c('div',{staticClass:"content",style:(_vm.getHeightWidth(_vm.StaticButtonElement.style))},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex",style:(_vm.getBackNextButtonPosition(
                    _vm.StaticButtonElement,
                    _vm.CurrentPage.value
                  ))},[(!_vm.StaticButtonElement.content.backButton.hideBackButton)?_c('button',{staticClass:"ButtonHover",staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                      _vm.StaticButtonElement.style,
                      _vm.isDesktopView
                    ) +
                    _vm.getBackNextButtonCss(
                      _vm.StaticButtonElement.content.backButton.style
                    )),on:{"click":_vm.backPage}},[_c('span',{style:({
                      opacity: `${_vm.StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${_vm.StaticButtonElement.content.backButton.style.fontSize}px`,
                    })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.backButton.text))])]):_vm._e(),(_vm.showNextBtn)?_c('button',{staticClass:"ButtonHover",staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                      _vm.StaticButtonElement.style,
                      _vm.isDesktopView
                    ) +
                    _vm.getBackNextButtonCss(
                      _vm.StaticButtonElement.content.nextButton.style
                    )),attrs:{"disabled":_vm.isLoadingQuestion},on:{"click":_vm.nextPage}},[(_vm.isLoadingQuestion)?_c('b-spinner',{staticClass:"mx-1",attrs:{"small":"","label":"Small Spinner"}}):_vm._e(),_c('span',{style:({
                      opacity: `${_vm.StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${_vm.StaticButtonElement.content.nextButton.style.fontSize}px`,
                    })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.nextButton.text))])],1):_vm._e()])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"px-0",class:[
        {
          'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
          'image-division col-6': _vm.isDesktopView,
        },
      ],style:(_vm.calculateImageWidth(
          _vm.pageDesign.imageBlock,
          _vm.isDesktopView,
          _vm.IsImageVariableWidthActive
        ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
              `pic-${_vm.getSelectedPage.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                _vm.IsImageVariableWidthActive,
                _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageOpacity(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }